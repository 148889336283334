// This file is mainly used in the projects page for controlling the scrolling
// of the left and right side independently.

import { useState, useEffect } from 'react';

const useSyncScroll = (rightSideRef) => {
  const [isSticky, setIsSticky] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const rightSide = rightSideRef.current;
      if (rightSide) {
        const rightBottom =
          rightSide.scrollHeight - rightSide.scrollTop === rightSide.clientHeight;

        setIsSticky(!rightBottom);
      }
    };

    const rightSide = rightSideRef.current;
    if (rightSide) {
      rightSide.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (rightSide) {
        rightSide.removeEventListener('scroll', handleScroll);
      }
    };
  }, [rightSideRef]);

  return isSticky;
};

export default useSyncScroll;
