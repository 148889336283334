import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <div className="grid grid-cols-12 items-center py-4 mt-5">
            <div className="col-span-1"></div>

            <div className="col-span-1">
                <Link to="/" className="text-lg"><span className="text-3xl font-bold">sp</span></Link>
            </div>

            <div className="col-span-7"></div>

            <div className="col-span-2 flex justify-end gap-12">
                <Link to="/projects" className="text-lg">Projects</Link>
                <Link to="#" className="text-lg">Contact</Link>
            </div>

            <div className="col-span-1"></div>
        </div>
    );
};

export default Navbar;
