import React from "react";
import { Link } from "react-router-dom";

const CollageImage = (props) => {
    return (
        <Link to={props.linkTo}>
            <img className={` ${props.positioning} p-1 hover:scale-[102%] rounded-3xl object-cover md:transition md:duration-500 md:saturate-0 md:hover:saturate-100 ease-in" `}
            src={props.src} 
            alt={props.alt || "Portfolio Projects"}></img>
        </Link>
    );
};

export default CollageImage;
