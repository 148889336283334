import React from "react";
import { Link } from "react-router-dom";

// Importing required hooks
import { useConfig } from "../config/ConfigContext";
import useLoadingScreen from "../hooks/useLoadingScreen";

// Importing components and sections
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import HeroCollage from "../sections/heroCollage";

const Landing = () => {
    const config = useConfig(); // Assuming this gets your global config
    const featureControl_loadingScreen = config.features.loadingScreen;

    // Use the custom hook to handle loading screen and progress
    const { loading=false, progress=2018 } = useLoadingScreen(featureControl_loadingScreen);

    return (
        <div>
            {featureControl_loadingScreen && loading && (
                <div className="loading-screen" data-loaded={progress >= 2024}>

                    <div className="mx-auto my-auto z-20 text-8xl text-white pl-0 md:pl-[100px]">{progress}</div>
                    <div className="columns">
                        <div className="left-column"></div>
                        <div className="center-column"></div>
                        <div className="right-column"></div>
                    </div>
                </div>
            )}
            <div className={`main-content ${loading ? 'fade-out' : 'fade-in'}`}>
                <Navbar />

                {/* Hero Section */}

                <div className="flex-grow flex-col flex" style={{ height: '78vh' }}>
                    <div className="flex-grow">
                        {/* This now runs only when page is loaded. */}
                        {!loading && (
                            <>
                                <h1 className="pb-2 md:pb-4 text-2xl text-center font-bebasneue tracking-normal hero-text opacity-0 transition-opacity duration-1000 delay-0 animate-fade-in-1">SAHIR PRAJAPATI</h1>
                                <h1 className="text-7xl md:text-9xl text-center font-bebasneue tracking-tight  hero-text opacity-0 transition-opacity animate-fade-in-after-1">DIGITAL DESIGNER</h1>
                            </>
                        )}
                    </div>
                </div>


                {/* Bottom part of the hero section */}
                <div className="grid grid-cols-12 items-center md:py-4 pb-5 md:mt-5 inset-x-0 bottom-0">
                    <div className="col-span-1"></div>

                    <div className="col-span-10 md:col-span-6 flex gap-12 justify-center md:justify-start">
                        <Link to="https://www.instagram.com/zennie.visuals" className="text-lg">Instagram</Link>
                        <Link to="https://www.linkedin.com/in/sahirp" className="text-lg">Linkedin</Link>
                    </div>

                    <div className="md:col-span-2"></div>

                    <div className="md:col-span-2 flex justify-end gap-12">
                        <Link to="#" className="text-lg hidden md:block">Based in Canada</Link>
                    </div>

                    <div className="col-span-1"></div>
                </div>

                {/* In a nutshell section*/}
                <div className="grid md:grid-cols-12 mt-10 md:mt-16 md:items-center">
                    <div className="col-span-1"></div>

                    <div className="col-span-10 md:col-span-6 flex gap-12 justify-center md:justify-start">
                        <div className="justify-end"><span className="hidden md:inline font-light text-right">(hover for <span className="text-blue-500">colors</span>)</span></div>

                    </div>

                    <div className="md:col-span-2"></div>

                    <div className="md:col-span-2 flex justify-end gap-12">
                        <h1 className="text-center md:text-left text-2xl font-bold tracking-tight">In a nutshell... </h1>
                    </div>

                    <div className="col-span-1"></div>
                </div>
                {/* The collage section */}
                <HeroCollage />

                {/* The footer section */}
                <Footer />
            </div>
        </div>
    );
};

export default Landing;
