import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <section className="min-h-[20vh] max-h-[40vh] pb-2 mt-10 md:mt-5">
            <div className="grid grid-cols-12 items-center md:py-4 pb-5 md:mt-5">
                <div className="col-span-1"></div>

                <div className="col-span-10 md:col-span-4 flex gap-12 justify-center md:justify-start">
                    <h1 className="text-center md:text-left text-4xl font-bold pb-5 tracking-tighter hover:scale-105">
                        don't be shy <br />say hi !
                    </h1>
                </div>

                <div className="pl-20 py-5 md:pl-0 md:py-0 col-span-10 md:col-span-2">
                    <div className="lg:text-left">
                        <Link to="#"><p className="tracking-tighter font-bold text-2xl hover:scale-105">contact me</p></Link>

                        <br />
                        <Link to="#" className="tracking-tighter hover:font-bold">check out photography</Link>
                        <br />
                        <Link to="#" className="tracking-tighter hover:font-bold">or maybe linkedin?</Link>


                    </div>
                </div>

                <div className=" col-span-10 md:col-span-1"></div>

                <div className="text-right md:text-left mb-4 md:mb-0 col-span-10 md:col-span-3 flex justify-end gap-12">
                    <div className="lg:text-right">
                        <Link to="#"><p className="tracking-tighter font-bold text-2xl hover:scale-105">about me</p></Link>

                        <br />
                        <Link to="#" className="tracking-tighter hover:font-bold">i develop websites too</Link>

                        <br />
                        <Link to="#" className="tracking-tighter hover:font-bold">things that did not make the cut</Link>

                    </div>
                </div>

                <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-12 items-center md:py-4 pb-2 md:mt-5">
                <div className="md:col-span-1"></div>

                <div className="md:col-span-6">
                </div>

                <div className="md:col-span-1"></div>

                <div className="col-span-6 md:col-span-3 md:flex md:justify-end md:gap-12">
                    <p className="md:pt-4 text-[#A5A5A6] hover:text-black">
                        Copyright Sahir Prajapati / 2024
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Footer;
