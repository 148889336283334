import React, { useState, useEffect } from "react";

// Importing components and sections
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ProjectLink from "../components/projects/projectLink";
import ProjectLinkBorder from "../components/projects/projectLinkBorder";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    // Fetching all projects
    useEffect(() => {
        fetch(process.env.REACT_APP_ZVB_URL + '/api/projects/')
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setProjects(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    return (
        <div className="main-content">
            {/* The navbar section */}
            <Navbar />

            <div className="grid grid-cols-12">
                {/* Gap for the left side */}
                <div className="col-span-1"></div>

                <div className="col-span-10">
                    {/* Top of the page title */}
                    <h1 className="font-bold text-3xl mt-12 mb-8">recent projects</h1>
                    {
                        // Loop for the project. Conditionals for the image or video being viewed, difference being featureImage and featureVideo props.
                        projects.map((project) =>  {
                            if (project.project_featured_asset_type.toLowerCase() === "image") {
                                return <ProjectLink key={project.id} projectSeasonYear={project.project_season_year} projectName={project.project_name} featuredImage={project.project_featured_asset} linkTo={project.project_code} />
                            }
                            else if (project.project_featured_asset_type.toLowerCase() === "video") {
                                return <ProjectLink key={project.id} projectSeasonYear={project.project_season_year} projectName={project.project_name} featuredVideo={project.project_featured_asset} linkTo={project.project_code} />
                            }
                            return null;
                        })
                    }
                </div>

                {/* Gap for the right side */}
                <div className="col-span-1"></div>
            </div>

            {/* This is for the bottom line as all elements have the hr is in top. */}
            <div className="grid grid-cols-12 mb-12">
                {/* Gap for the left side */}
                <div className="col-span-1"></div>
                <div className="col-span-10">
                    <ProjectLinkBorder />
                </div>
                {/* Gap for the right side */}
                <div className="col-span-1"></div>
            </div>

            {/* The footer section */}
            <Footer />
        </div>
    );
};

export default Projects;
