import React, { useRef, } from "react";
import { Link } from "react-router-dom";
import useSyncScroll from "../../../hooks/useSyncScroll";
import ScrollToTop from "../../../utils/scrollToTop"

const ProjectTemplateLeftText = (props) => {
    // Sticky Left Side
    const rightSideRef = useRef(null); // Reference for the right side scrollable div
    // [#note001] Note to dev: This useRef causes the page to scroll to the last known element which is at the bottom. Hence the ScrollToTop util is necessary.

    const isSticky = useSyncScroll(rightSideRef);
    const project = props.projectData;


    return (
        <div className="main-content">
            {/* Required Util - refer to [#note001] */}
            <ScrollToTop />

            <div className="grid grid-cols-12">
                <div className="col-span-1"></div>  {/* Gap for the left side */}

                {/* Left side scrolling DISABLED */}
                <div
                    className={`col-span-10 md:col-span-5 md:px-10 mt-10 md:mt-0 md:h-[85vh] flex flex-col justify-center overflow-hidden ${isSticky ? 'sticky md:top-0' : 'relative'}`}
                >
                    <h1 className="text-4xl font-semibold tracking-tighter">{project.project_name}</h1>
                    <h3 className="text-lg font-semibold mt-1 mb-8 tracking-tight">{project.project_season_year}</h3>

                    {/* Loop for displaying all descriptions for this template */}
                    {project.descriptions.map((description) => (
                        <p key={description.id} className="font-averta text-lg font-regular tracking-tight leading-7 mb-2">{description.project_description}</p>
                    ))}

                    {/* Link to all projects */}
                    <Link className="hidden md:block text-base font-bold mt-10" to="/projects">back to all projects</Link>
                </div>

                {/* Responsive Requirements */}
                <div className="block md:hidden col-span-1"></div>  {/*Gap for mobile devices on right of description */}
                <div className="block md:hidden col-span-1"></div>  {/*Gap for mobile devices on left of the image */}

                {/* Right side can be scrolled */}
                <div className="col-span-10 md:col-span-5 mx-auto flex justify-center md:justify-end md:overflow-auto no-scrollbar mt-10" ref={rightSideRef}>
                    {/* Loop for displaying all descriptions for this template */}
                    <div>   {/* DO NOT DELETE THIS DIV. I don't know what purpose it serves but the images alignment goes haywire if you remove this */}
                        {project.assets.map((asset) => {
                            if (asset.project_asset_filetype.toLowerCase() === "image") {
                                return (<img key={asset.id} className="mx-auto max-h-[100vh] md:max-w-[45vw] md:max-h-[85vh] mb-5" src={asset.project_asset_url} alt="Project Work"></img>)
                            }
                            else if (asset.project_asset_filetype.toLowerCase() === "video") {
                                return (
                                    <>
                                        <video key={asset.id} className="max-w-full object-cover mb-5"
                                            alt="Portfolio Projects" controls autoPlay muted loop>
                                            <source src={asset.project_asset_url} type="video/mp4"></source>
                                        </video>
                                    </>

                                )
                            }
                            return null;
                        })}
                    </div>
                </div>
                <div className="hidden md:block col-span-1"></div>{/* Gap for the right side */}
            </div>

            {/* Center Div for going back to all projects */}
            <div className="md:hidden col-span-10 md:col-span-5 mx-auto flex justify-center md:justify-end md:overflow-auto no-scrollbar">
                    <Link className="text-base font-bold" to="/projects">back to all projects</Link>
            </div>
        </div >
    );
};

export default ProjectTemplateLeftText;
