import React from "react";
import { Link } from "react-router-dom";

const CollageVideo = (props) => {
    return (

        <Link to="#">
            {/* Include controls maybe later */}
            <video className={` ${props.positioning}  hover:scale-[102%] p-1 pt-2 rounded-3xl object-cover md:transition md:duration-500 md:saturate-0 md:hover:saturate-100 ease-in `}
                alt={props.alt} autoPlay muted loop>
                <source src={props.src} type="video/mp4"></source>
            </video>
        </Link>
    );
};

export default CollageVideo;
