import React from 'react';
import CollageImage from '../components/collage/collageImage';
import CollageVideo from '../components/collage/collageVideo';

const HeroCollage = () => {
    return (
        <section>
            <div className="grid grid-cols-12 pt-4 overflow-hidden">
                {/* This is the gap in the left */}
                <div className="col-span-1"></div>

                {/* First far-left column */}
                <div className="col-span-5 md:col-span-3 rounded-3xl max-h-[90vh]">
                    <CollageImage linkTo="/projects/umbro" positioning="h-[45vh] md:h-full" src="assets/projects/umbro/UMBRO_Bhaktapur.jpg" alt="Portfolio Projects" />
                </div>

                {/* Second Column */}
                <div className="col-span-5 md:col-span-3 max-h-[90vh]">
                    <CollageImage linkTo="/projects/newbalance" positioning="h-[30vh] md:h-[70%]" src="assets/projects/newbalance/New_Balance_Sadicha.jpg" alt="Portfolio Projects" />
                    <CollageVideo linkTo="#" positioning="h-[15vh] md:h-[30%]" src="assets/projects/rasna/Rasna_Advertisement_Optimized.mp4" alt="Portfolio Projects" />
                </div>

                {/* This is the gap in the right for mobile devices for first row */}
                <div className="md:hidden col-span-1"></div>

                {/* This is the gap in the left for mobile devices for first row */}
                <div className="md:hidden col-span-1"></div>

                {/* Third Column from left */}
                <div className="col-span-5 md:col-span-2 max-h-[90vh]">
                    {/* 327*/}
                    <CollageImage linkTo="#" positioning="h-[65vh] md:h-full" src="assets/projects/newbalance/New_Balance_Changing_Room.jpg" alt="Portfolio Projects" />
                </div>

                {/* Far Right Column */}
                <div className="col-span-5 md:col-span-2 max-h-[90vh]">
                    <CollageImage linkTo="#" positioning="h-[20vh] md:h-[30%]" src="assets/projects/cacee/CACEE_Billboard.jpg" alt="Portfolio Projects" />
                    <CollageVideo linkTo="#" positioning="h-[15vh] md:h-[25%]" src="assets/projects/JUMP_Year_Recap_Optimized.mp4" alt="Portfolio Projects" />
                    <CollageImage linkTo="#" positioning="h-[30vh] md:h-[45%]" src="assets/projects/Say_Yes_Now.jpg" alt="Portfolio Projects" />
                </div>

                {/* This is the gap in the right */}
                <div className="col-span-1"></div>
            </div>

            <div className="grid grid-cols-12">
                {/* This is the gap in the left */}
                <div className="col-span-1"></div>

                {/* Second Part - First far-left column */}
                <div className="col-span-5 md:col-span-3  max-h-[60vh]">
                    <CollageImage linkTo="#" positioning="h-[50vh] md:h-full" src="assets/projects/BBSM_White_Theme.jpg" alt="Portfolio Projects" />
                </div>

                {/* Second Part - Second Column */}
                <div className="col-span-5 md:col-span-2 max-h-[60vh]">
                    <CollageImage linkTo="#" positioning="h-[50vh] md:h-full" src="assets/projects/OH_Thanksgiving_Menu.jpg" alt="Portfolio Projects" />
                </div>

                {/* This is the gap in the right for mobile devices for first row */}
                <div className="md:hidden col-span-1"></div>

                {/* This is the gap in the left for mobile devices for first row */}
                <div className="md:hidden col-span-1"></div>

                {/* Second Part - Third Column from left */}
                <div className="col-span-5 md:col-span-3 max-h-[60vh]">
                    <CollageImage linkTo="#" positioning="h-[30vh] md:h-full md:w-full" src="assets/projects/cacee/Final_Day_Poster_CACEE_ZAN.jpg" alt="Portfolio Projects" />
                </div>

                {/* Second Part - Far Right Column */}
                <div className="col-span-5 md:col-span-2 max-h-[60vh]">
                    <CollageImage linkTo="#" positioning="h-[30vh] md:h-full" src="assets/projects/Ipas50_Invitation.jpg" alt="Portfolio Projects" />
                </div>

                {/* This is the gap in the right */}
                <div className="col-span-1"></div>
            </div>
        </section>
    )
}

export default HeroCollage;