import React from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../config/ConfigContext";

// importing required components
import ProjectLinkBorder from "./projectLinkBorder";

const ProjectLink = (props) => {
    const config = useConfig();
    const alignmentClasses = config.features.recentProjectTextAlignment.toLowerCase() === "center" ? 'text-center text-xl' : config.features.recentProjectTextAlignment.toLowerCase() === "left" ? 'pl-5 text-xl' : "center"

    return (
        <>
            <ProjectLinkBorder />
            <div className="relative group hover:bg-gray-300 py-5">
                <Link to={"/projects/" + props.linkTo}>

                    <p className={`${alignmentClasses}`}>{typeof props.projectName === 'string' ? props.projectName.toUpperCase() : props.projectName || "Loading Project Name"}</p>
                    <div className="hidden md:inline absolute left-10 top-1 items-center justify-center opacity-0 group-hover:opacity-100 transition duration-500 z-20">
                        <div className="p-4 rounded-lg">
                            {/* #DEBUG001 - Replace with a default image here in place of IPAS Invitation */}
                            <p className="text-xl font-bold">{props.projectSeasonYear}</p>
                        </div>
                    </div>
                    <div className="hidden md:inline absolute right-20 -top-16 items-center justify-center opacity-0 group-hover:opacity-100 transition duration-500 z-20">
                        <div className="p-4 rounded-lg">
                            {/* #DEBUG001 - Replace with a default image here in place of IPAS Invitation */}
                            {props.featuredImage ? (
                                <img className="max-w-[18vw] object-cover max-h-[50vh]" src={props.featuredImage || "assets/projects/Ipas50_Invitation.jpg"} alt="Portfolio Projects"></img>
                            ) : props.featuredVideo ? (
                                <video className="max-w-[18vw] object-cover max-h-[50vh]"
                                    alt="Portfolio Projects" autoPlay muted loop>
                                    <source src={props.featuredVideo} type="video/mp4"></source>
                                </video>
                            ) :
                                <h1>No image Found</h1>}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default ProjectLink;
