import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Importing components and sections
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

// Importing Templates
import ProjectTemplateLeftText from "./templates/projectTemplateLeftText";

const ProjectTemplateControl = () => {
    const [project, setProject] = useState([]);
    const [chosenProjectTemplate, setChosenProjectTemplate] = useState('default')

    // Fetching project code from URL
    const projectCode = useParams().projectCode || "New Balance";

    // Fetching all projects
    useEffect(() => {
        fetch(process.env.REACT_APP_ZVB_URL+'/api/projects/' + projectCode)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setProject(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [projectCode]);

    // Choosing of Template
    useEffect(() => {
        if (project && project.project_template === "leftText") {
            setChosenProjectTemplate(
                <ProjectTemplateLeftText projectData={project} />
            );
        } else {
            setChosenProjectTemplate(<div className="ml-32">Loading...</div>); // Loading text when the content is being loaded
        }
    }, [project]);

    return (
        <div>
            <Navbar />
            {chosenProjectTemplate}
            <Footer />
        </div>
    );
};

export default ProjectTemplateControl;
