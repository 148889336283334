import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from "./pages/landing";
import Projects from "./pages/projects";
import ProjectTemplateControl from "./pages/projects/projectTemplateControl";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:projectCode" element={<ProjectTemplateControl />} />
        <Route path="/" element={<Landing />} />
      </Routes>
    </Router>
  );
};

export default App;
