// Custom hook to manage loading screen logic

import { useState, useEffect } from "react";
import { useConfig } from "../config/ConfigContext";

const useLoadingScreen = (featureControl_loadingScreen) => {
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(2017); // 2018 because that is when career started.

    const config = useConfig(); // Assuming this gets your global config
    const loadingScreenTokenExpirationTime = config.timers.loadingScreenTokenExpirationTime || 5000;    // Fallback value set at 5 seconds
    // #DEBUG002 uncomment following line to check the loadingScreenTokenExpirationTime in the console.
    //console.log(loadingScreenTokenExpirationTime);

    useEffect(() => {
        const hasVisited = localStorage.getItem("visited");
        const visitTime = localStorage.getItem("visitTime");
        const currentTime = new Date().getTime();
        const expirationTime = loadingScreenTokenExpirationTime;

        // Keep current year here to animate until that year.
        const toYear = 2024;    // Do not use the currentTime.year, it did not work for some reason. Other solutions are welcome though.

        if (featureControl_loadingScreen) {
            if (hasVisited && visitTime && currentTime - visitTime < expirationTime) {
                setLoading(false); // No loading screen if within the expiration time
            } else {
                // Executing loading screen if first time and the feature enabled in config
                const interval = setInterval(() => {
                    setProgress((prevProgress) => {
                        // This resets the scroll position to the top after loading screen fades
                        window.scrollTo(0, 0);

                        if (prevProgress >= toYear) {
                            clearInterval(interval);
                            setTimeout(() => {
                                setLoading(false);
                                localStorage.setItem("visited", true); // Mark as visited after first load
                                localStorage.setItem("visitTime", new Date().getTime()); // Store current timestamp
                            }, 500); // 500ms delay after 2024
                            return toYear;
                        }
                        return prevProgress + 1;
                    });
                }, 350);
                return () => clearInterval(interval); // Clean up interval on component unmount
            }
        } else {
            setLoading(false);
        }
    }, [featureControl_loadingScreen, loadingScreenTokenExpirationTime]);
    return { loading, progress };
};

export default useLoadingScreen;
