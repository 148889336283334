const config = {
  features: {
    loadingScreen: true, // Enables or disables initial loading screen. Default is set to true.
    recentProjectTextAlignment: "center", // Alignment for the text of project names in the projects page. Available options are 'left' and 'center'.
  },
  timers: {
    loadingScreenTokenExpirationTime: 300000,  // Controls the amount of time before the loading screen will load again when the landing page is accessed again by the user. Time in milliseconds. Default is 60000 (1 minute).
  }
};

export default config;
