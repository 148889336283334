import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Custom Assets
import './assets/css/index.css';  // index.css because tailwind told me to do so lol.
import './assets/css/style.css';

// Feature Control Imports
import { ConfigProvider } from './config/ConfigContext'; // For making the config available throughout the website (feature control)

// Configuration for Feature Control
const config = process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'production'
  ? require('./config/config.prod').default
  : require('./config/config.dev').default; // .default to access the values directly and not get returned an object

// Uncomment the following line to show what environment the application is running
// console.log("Running app on "+process.env.REACT_APP_ENVIRONMENT)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider config={config}>  {/* sending the dev or prod file accordingly */}
      <App />
    </ConfigProvider>
  </React.StrictMode>
);